<template>
  <div>
    <c-search-box @enter="getList">
      <template slot="search">
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
          <c-plant type="search" name="plantCd" v-model="searchParam.plantCd" />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-datepicker
            :range="true"
            type="month"
            label="기간"
            name="period"
            v-model="searchParam.period"
          />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <!-- 부서 -->
          <c-dept
            :isFirstValue="false"
            type="search"
            label="부서"
            name="checkDeptCd"
            v-model="searchParam.checkDeptCd">
          </c-dept>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <!-- 관리감독자 -->
          <c-field
            label="관리감독자"
            name="checkUserId"
            :isDeptManager="true"
            v-model="searchParam.checkUserId">
          </c-field>
        </div>
      </template>
    </c-search-box>
    <c-table
      ref="grid"
      title="관리감독자별 업무일지 건 수"
      :columns="grid.columns"
      :data="grid.data"
    >
      <template v-slot:customArea="{ props, col }">
        <template>
          <span>
            {{props.row[col.name] > 0 ? props.row[col.name] : ''}}
          </span>
        </template>
      </template>

      <!-- 버튼 영역 -->
      <template slot="table-button">
        <div>
          <q-btn-group outline>
            <c-btn label="LBLSEARCH" icon="search" @btnClicked="setHeader" />
          </q-btn-group>
        </div>
      </template>
    </c-table>
  </div>
</template>

<script>
import selectConfig from '@/js/selectConfig';
export default {
  name: "safety-check-status",
  props: {
  },
  data() {
    return {
      grid: {
        columns: [
        ],
        data: [],
      },
      searchParam: {
        plantCd: null,
        period: [],
        year: '',
        months: [],
      },
      editable: true,
      listUrl: '',
    };
  },
  watch: {
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  
  mounted() {
    this.init();
  },
  beforeDestroy() {},
  methods: {
    init() {
      this.editable = this.$route.meta.editable;
      let thisYear = this.$comm.getThisYear();
      this.searchParam.period = [thisYear + '-01', thisYear + '-12',]
      this.listUrl = selectConfig.sop.safetycheck.status.list.url;
      this.setHeader();
    },
    setHeader() {
      if (!this.searchParam.period || this.searchParam.period.length === 0) {
        this.grid.data = []
        this.grid.columns = [];
        window.getApp.$emit('ALERT', {
          title: '안내', // 안내
          message: '기간을 선택하세요.', // 기간을 선택하세요.
          type: 'warning', // success / info / warning / error
        });
        return;
      }
      this.grid.columns = [
        {
          fix: true,
          name: "plant_name",
          field: "plant_name",
          style: 'width:100px',
          label: "LBLPLANT",
          align: "center",
          sortable: false,
        },
        {
          fix: true,
          name: "dept_user_name",
          field: "dept_user_name",
          style: 'width:170px',
          label: "관리감독자",
          align: "left",
          sortable: false,
        },
      ]
      
      let _columns = [];
      let _parentColumns = {};

      let startYear = Number(this.searchParam.period[0].substring(0, 4));
      let startMonth = Number(this.searchParam.period[0].substring(5));

      let lastYear = Number(this.searchParam.period[1].substring(0, 4));
      let lastMonth = Number(this.searchParam.period[1].substring(5));

      if (startYear === lastYear) {
        for (let month=startMonth; month<=lastMonth; month++) {
          let monthStr = '';
          if (month < 10) {
            monthStr = '0' + month
          } else {
            monthStr = month
          }
          
          _columns.push({
            name: 'str' + startYear + monthStr,
            field: 'str' + startYear + monthStr,
            label: month + '월',
            type: 'custom',
            align: 'center',
          })
          this.searchParam.months.push(startYear + '' + monthStr)
        }
        _parentColumns = {
          name: startYear,
          field: startYear,
          label:  startYear + '년',
          align: 'center',
          child: _columns
        }
        this.grid.columns = this.$_.concat(this.grid.columns, _parentColumns)
        
        this.grid.columns.push({
          name: 'total',
          field: 'total',
          label: '합계',
          align: 'center',
        })
      } else {
        for (let year=startYear; year<=lastYear; year++) {
          
          _columns = [
          ]
          if (year===startYear) {
            for (let month=startMonth; month<=12; month++) {
              let monthStr = '';
              if (month < 10) {
                monthStr = '0' + month
              } else {
                monthStr = month
              }
              
              _columns.push({
                name: 'str' + year + monthStr,
                field: 'str' + year + monthStr,
                label: month + '월',
                type: 'custom',
                align: 'center',
              })
              this.searchParam.months.push(startYear + '' + monthStr);
            }
          } else if (year===lastYear) {
            for (let month=1; month<=lastMonth; month++) {
              let monthStr = '';
              if (month < 10) {
                monthStr = '0' + month
              } else {
                monthStr = month
              }
              
              _columns.push({
                name: 'str' + year + monthStr,
                field: 'str' + year + monthStr,
                label: month + '월',
                type: 'custom',
                align: 'center',
              })
              this.searchParam.months.push(lastYear + '' + monthStr);
            }
          } else {
            for (let month=1; month<=12; month++) {
              let monthStr = '';
              if (month < 10) {
                monthStr = '0' + month
              } else {
                monthStr = month
              }
              
              _columns.push({
                name: 'str' + year + monthStr,
                field: 'str' + year + monthStr,
                label: month + '월',
                type: 'custom',
                align: 'center',
              })
              this.searchParam.months.push(year + '' + monthStr);
            }
          }
          _parentColumns = {
            name: year,
            field: year,
            label:  year + '년',
            align: 'center',
            child: _columns
          }
          this.grid.columns.push(_parentColumns)
        }
        this.grid.columns.push({
          name: 'total',
          field: 'total',
          label: '합계',
          align: 'center',
        })
      }

      this.getList();
    },
    getList() {
      // 전체조회
      this.$http.url = this.listUrl;
      this.$http.type = 'GET';
      this.$http.param = this.searchParam;
      this.$http.request((_result) => {
        this.grid.data = _result.data;
      },);
    },
  },
};
</script>